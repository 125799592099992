import {toJSON} from './utils.js';

export default {
    data() {
        return {
            searchMode: false,
            keyword: '',
        };
    },
    watch: {
        searchMode(value) {
            this.$emit('search', value);
        },
        '$route.query.keyword': {
            handler(value) {
                var context = this;
                context.keyword = value;
                context.searchMode = value ? 'start' : false;
            },
            immediate: true,
        }, 
    },
    methods: {
        search() {
            var context = this;
            if(!context.keyword) {
                return; 
            }
            const route = context.$route;
            let name = route.name;
            const params = {
                ...route.params,
            };
            if (name === 'cica-data-item-detail') {
                name = 'cica-data-item';
                params.termId = route.params.termId;
            }
            context.$router.push({
                name,
                params,
                query: {
                    ...route.query,
                    keyword: context.keyword,
                }
            }).catch(_error => {});
        },
        blurSearch() {
            var context = this;
            if(!context.keyword) {
                context.searchMode = false; 
            }
        },
        cancelSearch(event) {
            var context = this;
            context.keyword = '';
            var route = context.$route;
            var query = toJSON(route.query);
            delete query.keyword;
            context.$router.push({
                name: route.name,
                params: route.params,
                query
            });
            context.searchMode = false;
            event.target.blur();
        },
    }
};
