import '@fortawesome/fontawesome-free/css/all.css';
import '../webfont/css/icon.css';
import '../style/reset.scss';
import '../style/grid.scss';
import '../style/grid3.scss';
import '../style/spacing.scss';
import '../style/common.scss';
import Vue from 'vue/dist/vue.common.js';
import VueRouter from 'vue-router';
import routes from './routes.js';
import myHeader from './header.js';
import sidebar from './sidebar.js';
import {sitePublicPath, urlMap, env} from './common.js';
import {post} from './utils.js';
import to from 'await-to-js';
import Cookies from 'js-cookie';
import footer from './footer.js';
import $ from 'jquery';

function start(el) {
    Vue.use(VueRouter);
    initVue(el);
}

function initVue(el) {
    new Vue({
        el,
        data() {
            return {
                sitePublicPath,
                error: '',
                errorCode: null,
                auth: null,
                permissions: [],
                openSidebar: false,
                allNavItems: getAllNavItems(),
                nav: getNav(),
                menu: [],
                searchMode: false,
                busy: false,
                breadcrumb: [],
            };
        },
        computed: {
            isProvider() {
                const user = this.auth.user;
                return user && user.userType === 2;
            },
            isAdmin() {
                return this.permissions.length > 0;
            },
            navId() {
                return this.$route?.path.slice(1).split('/')[0];
            },
            menuId() {
                return this.$route?.path.slice(1).split('/').pop();
            },
        },
        router: getRouter(),
        components: {
            sidebar,
            myHeader,
            'x-footer': footer,
        },
        async created() {
            var context = this;
            
            const url = new URL(location.href);
            const querystaffNo = url.searchParams.get('userId');
            const querySign = url.searchParams.get('sign');
            if (querystaffNo && querySign) {
                Cookies.remove('authed');
                sessionStorage.removeItem('token');
                localStorage.removeItem('token');
                return loginWithSign(context, querystaffNo, querySign);
            }

            var token = getTokenFromHash() || getTokenFromLocalStorage();
            if(token) {
                localStorage.setItem('token', token);
                Cookies.set('token', token); // /apiUser/userReadFile/接口会用到此cookie
                Cookies.set('authed', getExpireFromToken(token), { sameSite: 'Lax' });
                context.auth = {token};
            } else {
                location.href = 'anonymous.html#/login';
            }
        },
        methods: {
            getNav,
            track(type, files, ext) {
                // 已停用日志接口
                track(this, type, files, ext);
            },
            async track2(type, ext = {}) {
                // 已停用日志接口
                return;
                // eslint-disable-next-line no-unreachable
                const context = this;
                await to(new Promise(resolve => setTimeout(resolve, 300))); // 等待页面更新好
                var user = context.auth.user; 
                var userId = user.userId;
                var userType = user.userType;
                const breadcrumbs = [
                    $('.header-nav .header-nav-item-selected').text(),
                ];
                for (let li = $('.sidebar .router-link-exact-active').parent(); li.length > 0; ) {
                    const text = $('a', li).first().text().trim();
                    breadcrumbs.splice(1, 0, text);
                    li = $(li).parents('li').first();
                }
                const breadcrumbs$1 = breadcrumbs.join(' > ');
                if (!breadcrumbs$1) {
                    return;
                }
                var params = {
                    actionType: getActionType(type),
                    userId,
                    userType,
                    filePath: '',
                    breadcrumbs: breadcrumbs$1,
                    ext: JSON.stringify(ext),
                };
                var query = new URLSearchParams(params);
                var img = new Image();
                img.src = urlMap.track + '?' + query;
            },
        },
    });
}

async function loginWithSign(context, staffNo, sign) {
    const params = {
        staffNo,
        sign,
    };
    const [error, data] = await to(post(urlMap['login-with-sign'], params)); 
    if (error) {
        const map = {
            10404: '账号不存在',
            10650: '签名错误',
            10630: '首次登录，请复制下面链接，<br>在电脑浏览器中或手机微信客户端打开，进行身份验证',
        };
        context.errorCode = error.status;
        context.error = map[error.status] || '登录失败 ' + error.message;
    } else {
        location.href = sitePublicPath + '#/?token=' + data.token;
    }
}

function getTokenFromHash() {
    var hash = location.hash;
    var query = hash.slice(hash.indexOf('?'));
    var params = new URLSearchParams(query);
    return params.get('token');
}

function getTokenFromLocalStorage() {
    // token时效为3小时，所以cookie authed的时效也要一样
    // 此cookie为sessionCookie，当用户关闭浏览器后自动失效，
    // cookie值为创建时的时间戳，在使用时需检查是否已过期
    const authed = Cookies.get('authed');
    let validAuth = false;
    if (authed) {
        const maxAge = 3 * 3600 * 1000;
        validAuth = +authed + maxAge > Date.now();
    }
    if (validAuth) {
        return localStorage.getItem('token');
    }
}

function getExpireFromToken(token) {
    try {
        return JSON.parse(atob(token.split('.')[1])).exp * 1000;
    } catch (error) {
        return Date.now();
    }
}

function getRouter(context) {
    var router = new VueRouter({routes});
    router.beforeEach((_to, _from, next) => {
        const redirect = sessionStorage.getItem('redirect');
        if (redirect) {
            location.href = redirect;
        }
        next();
    });
    router.afterEach((_to, from, next) => {
        const token = _to.query.token;
        const keepToken = _to.query.keepToken;
        if (token && !keepToken) {
            // 隐藏地址栏token
            const query = { ..._to.query };
            delete query.token;
            router.replace({ query });
            if (/MicroMessenger/i.test(navigator.userAgent)) {
                location.reload(); // 修正微信“复制链接”功能
            }
        }
    });
    return router;
}

function track(context, type, files, ext = '') {
    // 已停用日志接口
    return;
    // eslint-disable-next-line no-unreachable
    var user = context.auth.user; 
    var userId = user.userId;
    var userType = user.userType;
    var breadcrumb = context.breadcrumb.map(item => item.title).join(' > ');
    var formatedFiles = files ? [].concat[files]: '';
    if(files && files.length) {
        formatedFiles = JSON.stringify(files.map(item => {
            if(item.title) {
                return item.suffix ? `${item.title}.${item.suffix}` : item.title;
            }
            return item;
        }));
    }
    // eslint-disable-next-line no-unreachable
    var params = {
        actionType: getActionType(type), 
        userId, 
        userType,
        filePath: formatedFiles,
        breadcrumbs: breadcrumb,
        ext,
    };
    var query = new URLSearchParams(params);
    var img = new Image();
    img.src = urlMap.track + '?' + query;
}

function getActionType(type) {
    var map = {
        'new-folder': 0,
        login: 1,
        download: 2,
        upload: 3,
        remove: 4,
        move: 5,
        rename: 6,
        share: 7,
        industryResearchPageView: 8,
        industryResearchDetailView: 9,
        industryResearchDownload: 10,
        newsPageView: 11,
        newsDetailView: 12,
        dataItemPageView: 13,
        dataItemDetailView: 14,
        dataReportPageView: 15,
        dataReportDetailView: 16,
        dataReportDownloadWord: 17,
        dataReportDownloadPdf: 18,
        filePageView: 19,
        filePreview: 20,
    };
    return map[type];
}

function getAllNavItems() {
    const map = {
        qa: [
            {
                termId: '945637677955517440',
                name: '企业类资料',
            },
            {
                termId: '945642304306285568',
                parent: '945637677955517440',
                name: '标准介绍材料',
            },
            {
                termId: '945643327360022528',
                parent: '945642304306285568',
                name: '集团',
            },
            {
                termId: '945643392724023296',
                parent: '945642304306285568',
                name: '集群',
            },
            {
                termId: '945642375659904000',
                parent: '945637677955517440',
                name: '企业大事记',
            },
            {
                termId: '945642455364192256',
                parent: '945637677955517440',
                name: '政府工作汇报',
            },
            {
                termId: '945637782817222656',
                name: '产品类资料',
            },
            {
                termId: '945643721922384896',
                parent: '945637782817222656',
                name: '金风装备',
            },
            {
                termId: '945643777111010304',
                parent: '945637782817222656',
                name: '金风服务',
            },
            {
                termId: '945638065177759744',
                name: '图片库',
            },
            {
                termId: '945638315288415232',
                name: '视频库',
            },
        ],
        production: [
            {
                termId: '945646106187081728',
                name: '企业类资料',
            },
            {
                termId: '945646983421565952',
                parent: '945646106187081728',
                name: '企业介绍资料',
            },
            {
                termId: '945647460737564672',
                parent: '945646983421565952',
                name: '集团资料',
            },
            {
                termId: '945647554668997632',
                parent: '945646983421565952',
                name: '集群资料',
            },
            {
                termId: '945647103236076544',
                parent: '945646106187081728',
                name: '政府工作汇报',
            },
            {
                termId: '954310811277522944',
                parent: '945646106187081728',
                name: '行业演讲观点',
            },
            {
                termId: '945647030976529408',
                parent: '945646106187081728',
                name: '企业大事记',
            },
            {
                termId: '945647193543663616',
                parent: '945646106187081728',
                name: '标准文件模板',
            },
            {
                termId: '945647804301385728',
                parent: '945647193543663616',
                name: 'VI视觉设计类',
            },
            {
                termId: '945647873964549120',
                parent: '945647193543663616',
                name: '线下活动类',
            },
            {
                termId: '945647933326608384',
                parent: '945647193543663616',
                name: '接待类',
            },
            {
                termId: '945647991476348928',
                parent: '945647193543663616',
                name: '公益类',
            },
            {
                termId: '945647250628052992',
                parent: '945646106187081728',
                name: '企业文化资料',
            },
            {
                termId: '945648055934420992',
                parent: '945647250628052992',
                name: '刊物',
            },
            {
                termId: '945648106685569024',
                parent: '945647250628052992',
                name: '图片',
            },
            {
                termId: '945648156731901952',
                parent: '945647250628052992',
                name: '视频',
            },
            {
                termId: '945648211622790144',
                parent: '945647250628052992',
                name: '课件',
            },
            {
                termId: '954311078857394176',
                parent: '945646106187081728',
                name: '品牌管理工具',
            },
            {
                termId: '945646179310604288',
                name: '产品类资料',
            },
            // {
            //     termId: '945648714037471232',
            //     parent: '945646179310604288',
            //     name: '金风能源',
            // },
            {
                termId: '945648512417337344',
                parent: '945646179310604288',
                name: '金风装备',
            },
            // {
            //     termId: '945648589236026368',
            //     parent: '945646179310604288',
            //     name: '金风服务',
            // },
            // {
            //     termId: '945648665568188416',
            //     parent: '945646179310604288',
            //     name: '金风零碳',
            // },
            {
                termId: '945649110072131584',
                parent: '945646179310604288',
                name: '金风国际',
            },
            // {
            //     termId: '945648788339618816',
            //     parent: '945646179310604288',
            //     name: '金风数字',
            // },
            // {
            //     termId: '945648847307317248',
            //     parent: '945646179310604288',
            //     name: '金风资本',
            // },
            // {
            //     termId: '945648969214772224',
            //     parent: '945646179310604288',
            //     name: '金风教育',
            // },
            // {
            //     termId: '945649037317666816',
            //     parent: '945646179310604288',
            //     name: '金风环保',
            // },
            // {
            //     termId: '945649171136969728',
            //     parent: '945646179310604288',
            //     name: '绿能化工',
            // },
            {
                termId: '945646247002487808',
                name: '图片库',
            },
            {
                termId: '945649643981797376',
                parent: '945646247002487808',
                name: '公司图片',
            },
            {
                termId: '945649917001698304',
                parent: '945646247002487808',
                name: '金风能源',
            },
            {
                termId: '945649716228758528',
                parent: '945646247002487808',
                name: '金风装备',
            },
            {
                termId: '945649770930856960',
                parent: '945646247002487808',
                name: '金风服务',
            },
            {
                termId: '945649827532914688',
                parent: '945646247002487808',
                name: '金风零碳',
            },
            {
                termId: '945650282791057408',
                parent: '945646247002487808',
                name: '金风国际',
            },
            // {
            //     termId: '945649980834797568',
            //     parent: '945646247002487808',
            //     name: '金风数字',
            // },
            // {
            //     termId: '945650045011782656',
            //     parent: '945646247002487808',
            //     name: '金风资本',
            // },
            // {
            //     termId: '945650152654466048',
            //     parent: '945646247002487808',
            //     name: '金风教育',
            // },
            // {
            //     termId: '945650216978287616',
            //     parent: '945646247002487808',
            //     name: '金风环保',
            // },
            // {
            //     termId: '945650342794870784',
            //     parent: '945646247002487808',
            //     name: '绿能化工',
            // },
            {
                termId: '945646316825073664',
                name: '视频库',
            },
            {
                termId: '945650428341809152',
                parent: '945646316825073664',
                name: '集团宣传片',
            },
            {
                termId: '945650492544097280',
                parent: '945646316825073664',
                name: '集群业务宣传片',
            },
            {
                termId: '945650543764859904',
                parent: '945646316825073664',
                name: '集群产品宣传片',
            },
            {
                termId: '1035882053889143808',
                parent: '945646316825073664',
                name: '英文宣传片',
            },
            {
                termId: '945650594218139648',
                parent: '945646316825073664',
                name: '其他视频',
            },
        ],
    };
    return map[env] || map['production'];
}

function getNav(parent) {
    const list = getAllNavItems();
    return list.filter(item => item.parent === parent);
}

start('#app');
